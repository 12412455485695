import ContactUs from "../ContactUs/ContactUs";
import Subscribe from "../Subscribe/Subscribe";

import supercool from "../../../assets/supercool.png";

import "./Footer.scss";
function Footer() {
  return (
    <>
      <Subscribe />
      <ContactUs />
      <div className="footer">
        <div className="footer__info">
          <p>
            <span className="footer__line">
              Environmental Abatement Council of Canada
            </span>
            <span className="footer__line">All Rights Reserved</span>
            <span className="footer__line">
              Designed by{" "}
              <a
                className="footer__link"
                href="https://www.supercoolcode.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="footer__image"
                  src={supercool}
                  alt="supercool logo"
                />
              </a>
            </span>
          </p>
        </div>
      </div>
    </>
  );
}

export default Footer;
