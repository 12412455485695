import React from "react";
import Hero from "../../components/Hero/Hero";
import "../../pages/Committee/Committee.scss";

function Committee() {
  return (
    <>
      <Hero title={"Committee"} />

      <div className="committee">
        <div className="committee__section">
          <div>
            <span className="committee__title--bold">ASBESTOS</span>
            <h3 className="committee__title">
              The goal of the committee will be to support the EACC Board of
              Directors and the Association in reviewing applicable mandates and
              directives from the EACC Board of Directors, with the intended
              focus of the betterment of the abatement and hazardous materials
              industry.
            </h3>
            <p className="committee__body">
              The Asbestos Committee will be chaired by David Bremner,
              Environmental Response Team (ERT) and the mandates, directives,
              and updates from the EACC Board of Directors will be communicated
              during the committee meetings, with an agenda provided in advance
              of the meetings.
            </p>
          </div>
          <div>
            <span className="committee__title--bold">CONSULTANTS</span>

            <h3 className="committee__title">
              The goal of the committee will be to support the EACC Board of
              Directors and the Association in reviewing applicable mandates and
              directives from the EACC Board of Directors, with the intended
              focus of the betterment of the abatement and hazardous materials
              industry.
            </h3>
            <p className="committee__body">
              The Consultants Committee will be chaired by Martin Ling, Stantec
              Consulting Ltd. and the mandates, directives, and updates from the
              EACC Board of Directors will be communicated during the committee
              meetings, with an agenda provided in advance of the meetings.
            </p>
          </div>
          <div>
            <span className="committee__title--bold">CONTRACTORS</span>

            <h3 className="committee__title">
              The goal of the committee will be to support the EACC Board of
              Directors and the Association in reviewing applicable mandates and
              directives from the EACC Board of Directors, with the intended
              focus of the betterment of the abatement and hazardous materials
              industry.
            </h3>
            <p className="committee__body">
              The Contractor Committee will be chaired by Norine Jones, Power
              Environmental/Power Vac Services and the mandates, directives, and
              the mandates, directives, and updates from the EACC Board of
              Directors will be communicated during the committee meetings, with
              an agenda provided in advance of the meetings.
            </p>
          </div>
          <div>
            <span className="committee__title--bold">LEAD</span>
            <h3 className="committee__title">
              The goal of the committee will be to support the EACC Board of
              Directors and the Association in reviewing applicable mandates and
              directives from the EACC Board of Directors, with the intended
              focus of the betterment of the abatement and hazardous materials
              industry.
            </h3>
            <p className="committee__body">
              The Lead Committee will be chaired by Bruce Decker, ECOH
              Management Inc. and the mandates, directives, and updates from the
              EACC Board of Directors will be communicated during the committee
              meetings, with an agenda provided in advance of the meetings.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Committee;
